export const GAutoComplete = async (text) =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject("Need valid text input");
    }

    // for use in things like GatsbyJS where the html is generated first
    if (typeof window === "undefined") {
      return reject("Need valid window object");
    }

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: text,
          componentRestrictions: { country: "au" },
          fields: ["address_components", "formatted_address"],
        },
        resolve
      );
      // .setFields(["address_components", "formatted_address"]);
    } catch (e) {
      reject(e);
    }
  });

export const addressSearch = async (event, value) => {
  return new Promise((resolve, reject) => {
    try {
      // const pSelectedOption = deliveryOptions.find(
      //   (e) => e?.label === event?.target?.value
      // );
      if (value?.data?.place_id) {
        new window.google.maps.places.PlacesService(event?.target).getDetails(
          { placeId: value?.data?.place_id },
          (place, status) => {
            if (status === "OK") {
              resolve(place);
            }
          }
        );
      }
    } catch (error) {
      reject(error);
    }
  });
};

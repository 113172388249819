import { TextAreaField } from "@aws-amplify/ui-react";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  // FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { VishcorpContext } from "Context";
// import { useEffect } from "react";
import { useContext } from "react";

const serviceOptions = [
  { label: "Standard", value: "Standard" },
  { label: "Country", value: "Country" },
  { label: "Urgent", value: "Urgent" },
  { label: "Country 3 Day Delivery", value: "Country 3 Day Delivery" },
  { label: "COD", value: "COD" },
  { label: "Country NCA", value: "Country NCA" },
];

const getOrderSignature = () => false;

export default function OrderInfoHelper(props) {
  const { selectedOrder, drivers } = useContext(VishcorpContext);

  const { selectedDriver, setSelectedDriver, onDispatch } = props;

  const driverOptions = [
    "Not Assigned",
    ...(drivers?.map((e) => e?.driverName) ?? []),
  ];

  // console.log("selectedOrder", selectedOrder);
  return (
    <div style={{ margin: 5 }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <FormControl sx={{ minWidth: 120 }} fullWidth>
              <InputLabel id="service-type">Service Type</InputLabel>
              <Select
                disabled
                labelId="service-type"
                onChange={(e) => {}}
                fullWidth
                label="Service Type"
                value={selectedOrder?.parcelDetails?.serviceType}
              >
                {serviceOptions?.map((e, i) => (
                  <MenuItem value={e?.value} key={i}>
                    {e?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <br />
          <Grid item xs={12}>
            <FormControl sx={{ minWidth: 120 }} fullWidth>
              <InputLabel id="package-type">Package Type</InputLabel>
              <Select
                disabled
                label="Package Type"
                onChange={(e) => {}}
                labelId="package-type"
                value="Box"
                fullWidth
              >
                <MenuItem value="Box">Box</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label="Reference"
            variant="outlined"
            onChange={(e) => {}}
            value={selectedOrder?.parcelDetails?.reference}
            style={{ width: "100%", marginBottom: 15 }}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Pieces"
                variant="outlined"
                onChange={(e) => {}}
                style={{ width: "100%", marginBottom: 15 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Weight"
                variant="outlined"
                onChange={(e) => {}}
                style={{ width: "100%", marginBottom: 15 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container spacing={1} sx={{ marginLeft: 2, marginRight: 2 }}>
        <Grid item xs={8}>
          <Autocomplete
            options={driverOptions}
            value={selectedDriver ?? "Not Assigned"}
            onChange={(event, newValue) => {
              setSelectedDriver(newValue);
            }}
            fullWidth
            disabled
            autoComplete
            renderInput={(params) => {
              return <TextField {...params} label="Drivers" />;
            }}
          />
        </Grid>
        <Grid item xs={4} sx={{ display: "flex" }}>
          <Button variant="contained" onClick={onDispatch} disabled>
            Dispatch
          </Button>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container spacing={1}>
        {/* <Grid item xs={5}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              Driver Number
            </Grid>
            <Grid item xs={6} marginLeft={5}>
              <Button variant="contained">Dispatch</Button>
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Divider orientation="vertical" flexItem /> */}
        <Grid item xs={12} marginLeft={2} marginRight={2}>
          <Grid item xs={12}>
            <TextAreaField
              id="outlined-basic"
              onChange={(e) => {}}
              label="Special Instructions"
              variant="outlined"
              value={selectedOrder?.parcelDetails?.specialInstructions}
              style={{ width: "100%", marginTop: 15 }}
            />
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              Signature
            </Grid>
            <Grid item xs={12}>
              {getOrderSignature()
                ? "Signature Canvas"
                : "Signature Not Available"}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        {/* <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained">Complete Order</Button>
        </Grid> */}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "left", marginLeft: 5 }}
        >
          <Button variant="contained">Cancel Order</Button>
        </Grid>
      </Grid>
    </div>
  );
}

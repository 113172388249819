import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";

export default function CustomCheckBox(props) {
  const { control, required, errors, value, setValue, options, label } = props;
  const handleChange = (e) => {
    setValue({
      ...(value ?? {}),
      [e?.target?.name]: e?.target?.checked,
    });
  };

  return (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel>
      {options && (
        <Controller
          control={control}
          name={label}
          rules={{ required: required ? "Required*" : false }}
          render={({ field }) => {
            return (
              <FormGroup {...field} row>
                {options?.map((e, i) => (
                  <FormControlLabel
                    key={`${e}${i}`}
                    control={
                      <Checkbox
                        checked={value?.[e] ?? false}
                        name={e}
                        onChange={handleChange}
                      />
                    }
                    label={e}
                  />
                ))}
              </FormGroup>
            );
          }}
        />
      )}
      {errors?.[label] && required && (
        <FormHelperText sx={{ mt: -1 }} error>
          {errors?.[label]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
}

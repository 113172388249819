// import { Button } from "@mui/material";
import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { VishcorpContext } from "Context";
import moment from "moment";

export default function OrderLists(props) {
  const { drivers } = useContext(VishcorpContext);
  const { orders, onEditClick } = props;
  const getBackgroundColor = (status) => {
    switch (status) {
      case "Active":
        return "blue";
      case "Delivered":
        return "green";
      case "Incoming":
        return "orange";
      case "Cancel":
        return "red";
      default:
        return "black";
    }
  };

  const getNewStatusMappings = (status) => {
    switch (status) {
      case "Active":
        return "Confirmed";
      case "Delivered":
        return "Delivered";
      case "Incoming":
        return "Incoming";
      case "Cancel":
        return "Cancelled";
      case "Confirmed":
        return "Dispatched";
      default:
        return "black";
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: 450, overflow: "scroll" }}
    >
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Order Number</b>
            </TableCell>
            <TableCell>
              <b>Status</b>
            </TableCell>
            <TableCell>
              <b>Time</b>
            </TableCell>
            {/* <TableCell>
              <b>Ordered By</b>
            </TableCell> */}
            <TableCell>
              <b>Pickup At</b>
            </TableCell>
            <TableCell>
              <b>Deliver To</b>
            </TableCell>
            <TableCell>
              <b>Driver</b>
            </TableCell>
            {/* <TableCell>
              <b>Status</b>
            </TableCell> */}
            {/* <TableCell>
              <b>Actions</b>
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders && orders?.length > 0 ? (
            orders?.map((row) => (
              <TableRow
                hover
                role="checkbox"
                onClick={(event) => {
                  onEditClick(row);
                }}
                key={row.orderNumber}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell component="th" scope="row">
                  {row?.orderNumber}
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: getBackgroundColor(row?.orderStatus),
                    color: "white",
                  }}
                >
                  {getNewStatusMappings(row?.orderStatus)}
                </TableCell>
                <TableCell>
                  {moment(row?.orderTimestamp).format("YYYY-MM-DD HH:mm:ss")}
                </TableCell>
                {/* <TableCell>{row?.ordererID}</TableCell> */}
                <TableCell>{row?.pickupDetails?.address}</TableCell>
                <TableCell>{row?.deliveryDetails?.address}</TableCell>
                <TableCell>
                  {drivers?.find(
                    (e) => e?.driverNumber === parseInt(row?.assignedDriver)
                  )?.driverName ?? "Not Assigned"}
                </TableCell>
                {/* <TableCell>{row?.orderStatus}</TableCell> */}
                {/* <TableCell>
                  <Button
                    onClick={() => {
                      onEditClick(row);
                    }}
                  >
                    Edit
                  </Button>
                </TableCell> */}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No Records to Display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

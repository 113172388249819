import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog";
import { useContext, useState } from "react";
import AddUser from "./AddUser";
import { VishcorpContext } from "Context";
import { updateCostCenterUsers } from "./functions";
import AddGlobalUser from "./AddGlobalUser";
import GlobalUserLists from "./GlobalUserLists";

export default function Users(props) {
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [selectedGlobalUser, setSelectedGlobalUser] = useState(undefined);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openAddGlobalUser, setOpenAddGlobalUser] = useState(false);

  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openDeleteGlobalUser, setOpenDeleteGlobalUser] = useState(false);
  const {
    userInfo,
    customerDetails,
    refreshCustomerDetails,
    organizationSettings,
  } = useContext(VishcorpContext);

  const globalUsers = customerDetails?.globalUsers;

  const costCenterUsers = customerDetails?.costCenters?.find(
    (e) => e?.costCenterID === userInfo?.attributes?.["custom:costCenterID"]
  ).costCenterUsers;

  return (
    <div style={{ padding: 7 }}>
      <Paper sx={{ height: "100vh", padding: 2 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Users</Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Button variant="contained" onClick={() => setOpenAddUser(true)}>
              Add User
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              onClick={() => {
                if (selectedUser !== undefined) {
                  setOpenDeleteUser(true);
                }
              }}
              color="error"
            >
              Delete User
            </Button>
          </div>
        </div>
        <TableContainer component={Paper} sx={{ marginTop: 5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Email</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {costCenterUsers?.length > 0 ? (
                costCenterUsers?.map((e, i) => (
                  <TableRow
                    key={i}
                    hover
                    selected={
                      selectedUser?.costCenterUserID === e?.costCenterUserID
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSelectedUser(e);
                    }}
                  >
                    <TableCell>{e?.userName}</TableCell>
                    <TableCell>{e?.email}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Global Users</Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenAddGlobalUser(true)}
            >
              Add Global User
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              onClick={() => {
                if (selectedUser !== undefined) {
                  setOpenDeleteGlobalUser(true);
                }
              }}
              color="error"
            >
              Delete Global User
            </Button>
          </div>
        </div>

        <GlobalUserLists
          globalUsers={globalUsers}
          setSelectedGlobalUser={setSelectedGlobalUser}
          selectedGlobalUser={selectedGlobalUser}
        />
      </Paper>
      <AlertDialog
        customControls={false}
        open={openAddUser}
        handleClose={() => setOpenAddUser(false)}
        dialogTitle="Add User"
        dialogContent={<AddUser handleClose={() => setOpenAddUser(false)} />}
      />
      <AlertDialog
        customControls={false}
        open={openAddGlobalUser}
        handleClose={() => setOpenAddGlobalUser(false)}
        dialogTitle="Add Global User"
        dialogContent={
          <AddGlobalUser
            handleClose={() => setOpenAddGlobalUser(false)}
            refreshCustomerDetails={refreshCustomerDetails}
          />
        }
      />
      <AlertDialog
        open={openDeleteUser}
        handleClose={() => setOpenDeleteUser(false)}
        dialogTitle={`Confirm Delete User: ${selectedUser?.userName} ?`}
        handleConfirm={async () => {
          await updateCostCenterUsers(
            userInfo?.attributes?.["custom:customerID"],
            userInfo?.attributes?.["custom:costCenterID"],
            costCenterUsers?.filter(
              (e) => e?.costCenterUserID !== selectedUser?.costCenterUserID
            ),
            selectedUser,
            organizationSettings?.organizationTablePrefix
          );
          await refreshCustomerDetails();
          setOpenDeleteUser(false);
        }}
      />
      <AlertDialog
        open={openDeleteGlobalUser}
        handleClose={() => setOpenDeleteGlobalUser(false)}
        dialogTitle={`Confirm Delete Global User: ${selectedGlobalUser?.globalUserName} ?`}
        handleConfirm={async () => {
          await refreshCustomerDetails();
          setOpenDeleteGlobalUser(false);
        }}
      />
    </div>
  );
}

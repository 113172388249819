import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller } from "react-hook-form";

export default function CustomSelect(props) {
  const { value, required, control, errors, label, setValue, options } = props;
  return (
    <FormControl
      fullWidth
      style={{ width: "100%", marginBottom: 15 }}
      size="small"
      error={errors?.[label] && required}
    >
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={label}
        rules={{ required: required ? "Required*" : false }}
        render={({ field }) => (
          <Select
            {...field}
            label={label}
            fullWidth
            // value={value ?? ""}
            onChange={(e) => {
              field?.onChange(e);
              setValue(e?.target?.value);
            }}
            value={value ?? ""}
            error={errors?.[label] && required}
          >
            {options &&
              options?.map((e, i) => (
                <MenuItem value={e} key={`${e}${i}`}>
                  {e}
                </MenuItem>
              ))}
          </Select>
        )}
      />
      {errors?.[label] && required && (
        <FormHelperText error>{errors?.[label]?.message}</FormHelperText>
      )}
    </FormControl>
  );
}

import * as React from "react";
import { Buffer } from "buffer";
import {
  styled,
  // , useTheme
} from "@mui/material/styles";
import Box from "@mui/material/Box";
// import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";
import { Auth } from "aws-amplify";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
// import ListIcon from "@mui/icons-material/List";
// import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
// import { primaryAppBarColor } from "../../constants";
// import SearchBar from "Components/Search/SearchBar";
import AccountOptions from "./AccountOptions";
import { primaryAppBarColor } from "constants";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { VishcorpContext } from "Context";

const drawerWidth = 240;

// const openedMixin = (theme) => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
// });

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
// });

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": openedMixin(theme),
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": closedMixin(theme),
//   }),
// }));

// const menuItems = {
//   orders: {
//     label: "Orders",
//     icon: <ListIcon />,
//     link: "/Orders",
//   },
//   newOrder: {
//     label: "New Order",
//     icon: <AddCircleIcon />,
//     link: "/newOrder",
//   },
//   customers: {
//     label: "Customers",
//     icon: <PeopleAltIcon />,
//     link: "/Customers",
//   },
//   drivers: {
//     label: "Drivers",
//     icon: <DirectionsCarIcon />,
//     link: "/Drivers",
//   },
// };

export default function MenuDrawer(props) {
  // const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  const { userInfo, customerDetails, customerLogo } =
    React.useContext(VishcorpContext);
  const customerCompanySettings = customerDetails?.customerCompanySettings;
  let navigate = useNavigate();
  const [selectedOption, setSelectedOption] = React.useState("");
  const [parsedCustomerLogo, setParsedCustomerLogo] = React.useState(undefined);

  React.useEffect(() => {
    if (!customerLogo?.data) return;
    setParsedCustomerLogo(
      `data:image/png;base64, ${
        JSON.parse(new Buffer.from(customerLogo?.data).toString())?.data
      }`
    );
  }, [customerLogo]);

  const handleSelection = (event, newAlignment) => {
    setSelectedOption(newAlignment);
  };

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        // open={open}
        style={{
          background: primaryAppBarColor,
        }}
      >
        <Toolbar>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" noWrap sx={{ flexGrow: 1, width: "50%" }}>
            <Link
              href="#"
              onClick={() => {
                setSelectedOption(undefined);
                navigate("/");
              }}
              color="inherit"
              underline="none"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  verticalAlign: "center",
                }}
              >
                {customerCompanySettings?.logo !== undefined &&
                parsedCustomerLogo !== undefined ? (
                  <img
                    src={parsedCustomerLogo}
                    alt="No Logo"
                    style={{ height: "30px", marginRight: 10 }}
                  />
                ) : (
                  ""
                )}
                {customerCompanySettings?.companyDisplayName ??
                  customerDetails?.customerName}
              </div>
            </Link>
          </Typography>
          {/* <SearchBar /> */}
          {!(userInfo?.attributes?.["custom:isCostCenterUser"] === "1") && (
            <div>
              <ToggleButtonGroup
                value={selectedOption}
                exclusive={true}
                onChange={handleSelection}
              >
                <ToggleButton
                  value="Settings"
                  onClick={() => {
                    navigate("/Settings");
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <SettingsIcon />
                    <div style={{ fontSize: 15 }}>Settings</div>
                  </div>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
          <AccountOptions
            color="inherit"
            onClick={async () => {
              try {
                await Auth.signOut();
              } catch (error) {
                console.error(error);
              }
            }}
          />
        </Toolbar>
      </AppBar>
      {/* <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {Object.keys(menuItems).map((key, index) => (
            <ListItem key={key} disablePadding sx={{ display: "block" }}>
              <Tooltip title={menuItems[key]?.label}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    navigate(menuItems[key]?.link);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {menuItems[key]?.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={menuItems[key]?.label}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer> */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props?.children}
      </Box>
    </Box>
  );
}

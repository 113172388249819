import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getCustomer } from "API";
import { addCostCenter, deleteCostCenter } from "API/CostCenters";
import AlertDialog from "Components/AlertDialog";
import { VishcorpContext } from "Context";
import { useContext, useEffect, useState } from "react";
import AddCostCenter from "./AddCostCenter";

export default function CostCenters(props) {
  const { organizationSettings, customerDetails } = useContext(VishcorpContext);
  const organizationID = organizationSettings?.organizationID;

  const [costCenters, setCostCenters] = useState(undefined);
  const [selectedCostCenter, setSelectedCostCenter] = useState(undefined);
  const [openAddCostCenter, setOpenAddCostCenter] = useState(false);
  const [openDeleteCostCenter, setOpenDeleteCostCenter] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const refreshCustomer = async () => {
    if (!organizationSettings?.organizationTablePrefix) {
      return;
    }
    await getCustomer(organizationSettings?.organizationTablePrefix)
      .then((res) => {
        setCostCenters(res?.costCenters ?? []);
      })
      .catch(console.error);
  };

  useEffect(() => {
    refreshCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID]);

  return (
    <Paper style={{ marginLeft: 5, height: "100%", padding: 10 }}>
      <Typography variant="h5">Cost Centers</Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setOpenAddCostCenter(true);
          }}
          sx={{ marginRight: 5 }}
        >
          Add
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            if (selectedCostCenter === undefined) {
              return;
            }
            setOpenDeleteCostCenter(true);
          }}
        >
          Delete
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Cost Center</b>
              </TableCell>
              <TableCell>
                <b>Address</b>
              </TableCell>
              <TableCell>
                <b>City</b>
              </TableCell>
              <TableCell>
                <b>Postal</b>
              </TableCell>
              <TableCell>
                <b>Province</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {costCenters?.length > 0 ? (
              costCenters?.map((e, i) => (
                <TableRow
                  hover
                  selected={
                    selectedCostCenter?.costCenterName === e?.costCenterName
                  }
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedCostCenter(e);
                  }}
                >
                  <TableCell>{e?.costCenterName}</TableCell>
                  <TableCell>{e?.address?.address}</TableCell>
                  <TableCell>{e?.city}</TableCell>
                  <TableCell>{e?.postalCode}</TableCell>
                  <TableCell>{e?.province}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <AlertDialog
        customControls={false}
        open={openAddCostCenter}
        handleClose={() => {
          setOpenAddCostCenter(false);
        }}
        dialogTitle={`Add Cost Center`}
        dialogContent={
          <AddCostCenter
            handleClose={() => {
              setOpenAddCostCenter(false);
            }}
            openAlertMessage={openAlertMessage}
            setOpenAlertMessage={setOpenAlertMessage}
            errorMessage={errorMessage}
            onAddCostCenter={async (e) => {
              const addCCRes = await addCostCenter(
                customerDetails?.customerID,
                e,
                organizationSettings?.organizationID,
                organizationSettings?.organizationTablePrefix
              );
              // setCostCenters((pre) => [...(pre ?? []), e]);
              if (String(addCCRes?.response?.statusCode) === "400") {
                setErrorMessage(addCCRes?.response);
                setOpenAlertMessage(true);
              } else {
                await refreshCustomer();
                setOpenAddCostCenter(false);
              }
            }}
          />
        }
      />
      <AlertDialog
        open={openDeleteCostCenter}
        handleClose={() => {
          setOpenDeleteCostCenter(false);
        }}
        handleConfirm={async () => {
          await deleteCostCenter(
            customerDetails?.customerID,
            selectedCostCenter?.costCenterID,
            organizationSettings?.organizationID,
            organizationSettings?.organizationTablePrefix
          );
          await refreshCustomer();
          setOpenDeleteCostCenter(false);
        }}
        dialogTitle={`Confirm Delete Cost Center: ${selectedCostCenter?.costCenterName}`}
      />
    </Paper>
  );
}

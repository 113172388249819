const TEST_FLAG = false;

export const getIncomingCustomerOrders = async (
  userInfo,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management?action=LIST_INCOMING_CUSTOMER_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        customerID: userInfo?.attributes?.["custom:customerID"],
        costCenterID: userInfo?.attributes?.["custom:costCenterID"],
        costCenterUserID: userInfo?.attributes?.["custom:costCenterUserID"],
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getActiveCustomerOrders = async (
  userInfo,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management?action=LIST_ACTIVE_CUSTOMER_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        customerID: userInfo?.attributes?.["custom:customerID"],
        costCenterID: userInfo?.attributes?.["custom:costCenterID"],
        costCenterUserID: userInfo?.attributes?.["custom:costCenterUserID"],
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getCancelledCustomerOrders = async (
  userInfo,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management?action=LIST_CANCELLED_CUSTOMER_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        customerID: userInfo?.attributes?.["custom:customerID"],
        costCenterID: userInfo?.attributes?.["custom:costCenterID"],
        costCenterUserID: userInfo?.attributes?.["custom:costCenterUserID"],
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getDeliveredCustomerOrders = async (
  userInfo,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management?action=LIST_DELIVERED_CUSTOMER_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        customerID: userInfo?.attributes?.["custom:customerID"],
        costCenterID: userInfo?.attributes?.["custom:costCenterID"],
        costCenterUserID: userInfo?.attributes?.["custom:costCenterUserID"],
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getConfirmedCustomerOrders = async (
  userInfo,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management?action=LIST_CONFIRMED_CUSTOMER_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        customerID: userInfo?.attributes?.["custom:customerID"],
        costCenterID: userInfo?.attributes?.["custom:costCenterID"],
        costCenterUserID: userInfo?.attributes?.["custom:costCenterUserID"],
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const updateOrder = async (
  selectedOrder,
  modifiedStatus,
  pDriverNumber,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_ORDER",
        orderID: selectedOrder?.orderID,
        orderTimestamp: selectedOrder?.orderTimestamp,
        orderStatus: modifiedStatus,
        assignedDriver: String(pDriverNumber),
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const createOrder = async (
  userInfo,
  deliveryDetails,
  pickupDetails,
  parcelDetails,
  orderAttachments,
  organizationSettings
) => {
  let params = {
    action: "CREATE_ORDER",
    orderTimestamp: new Date().toISOString(),
    postCode: deliveryDetails?.postCode,
    pickupPostCode: pickupDetails?.postCode,
    ordererID: userInfo?.attributes?.["custom:customerID"],
    ordererCompany: userInfo?.attributes?.email,
    pickupDetails,
    deliveryDetails,
    parcelDetails,
    orderAttachments,
    organizationID: organizationSettings?.organizationID,
    organizationTablePrefix: organizationSettings?.organizationTablePrefix,
  };
  if (userInfo?.attributes?.["custom:isCostCenter"] === "1") {
    params.fromCostCenter = true;
    params.costCenterID = userInfo?.attributes?.["custom:costCenterID"];
  }
  if (userInfo?.attributes?.["custom:isCostCenterUser"] === "1") {
    params.fromCostCenterUser = true;
    params.costCenterID = userInfo?.attributes?.["custom:costCenterID"];
    params.costCenterUserID = userInfo?.attributes?.["custom:costCenterUserID"];
  }

  if (TEST_FLAG) {
    // create DWAYBILL ORDER
    await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
      {
        method: "POST",
        body: JSON.stringify({
          ...params,
          action: "CREATE_DIGITAL_WAYBILL_ORDER",
        }),
      }
    )
      .then((res) => res.json())
      .catch(console.error);
  }

  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify(params),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

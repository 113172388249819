export const updateCustomerCompanySettings = async (
  customerID,
  customerCompanySettings,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_CUSTOMER_COMPANY_SETTINGS",
        customerID,
        customerCompanySettings,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const updateCustomerLogo = async (organizationID, customerID, logo) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_CUSTOMER_LOGO",
        customerID,
        organizationID,
        logo,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getCustomerLogo = async (organizationID, customerID) => {
  if (!organizationID || !customerID) return;

  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_CUSTOMER_LOGO",
        customerID,
        organizationID,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res.response.Body)
    .catch(console.error);
};

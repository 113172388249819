export const getGlobalUsersByPricingList = async (
  pricingList,
  surchargeSet,
  orgnanizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_GLOBAL_USERS_BY_PRICING_LIST",
        pricingList,
        surchargeSet,
        orgnanizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.Items)
    .catch(console.error);
};

export const getGlobalUsersByCustomer = async (
  customerID,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_GLOBAL_USERS_BY_CUSTOMER",
        customerID,
        organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.Items)
    .catch(console.error);
};

export const addGlobalUserToCustomer = async (
  customerID,
  globalUserDetails,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "ADD_GLOBAL_USERS_TO_CUSTOMER",
        customerID,
        globalUserDetails,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

import { getCustomer, getOrganizationSettings } from "API";
import { getDrivers } from "API/Drivers";
import {
  getActiveCustomerOrders,
  getCancelledCustomerOrders,
  getConfirmedCustomerOrders,
  getDeliveredCustomerOrders,
  getIncomingCustomerOrders,
} from "API/Orders";
import { Hub } from "aws-amplify";
import { Auth } from "aws-amplify";
import VishcorpClientAuthenticator from "Components/Authentication";
import { VishcorpContext } from "Context";
import MenuDrawer from "MenuBar";
import { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Settings from "Settings";
import { getCustomerLogo } from "Settings/CompanySettings/functions";
import HomeScreen from "UI/HomeScreen";

function App() {
  const [organizationSettings, setOrganizationSettings] = useState(undefined);
  const [drivers, setDrivers] = useState(undefined);
  const [incomingOrders, setIncomingOrders] = useState(undefined);
  const [activeOrders, setActiveOrders] = useState(undefined);
  const [cancelledOrders, setCancelledOrders] = useState(undefined);
  const [confirmedOrders, setConfirmedOrders] = useState(undefined);
  const [completedOrders, setCompletedOrders] = useState(undefined);
  const [selectedOrder, setSelectedOrder] = useState(undefined);
  const [customerDetails, setCustomerDetails] = useState(undefined);
  const [customerLogo, setCustomerLogo] = useState(undefined);

  const [userInfo, setUserInfo] = useState(undefined);

  const organizationTablePrefix = organizationSettings?.organizationTablePrefix;

  const refreshOrder = async (pUserInfo, pOrganizationTablePrefix) => {
    await getIncomingCustomerOrders(pUserInfo, pOrganizationTablePrefix)
      .then((res) => setIncomingOrders(res?.incomingOrders?.Items))
      .catch(console.error);
    await getActiveCustomerOrders(pUserInfo, pOrganizationTablePrefix)
      .then((res) => {
        setActiveOrders(res?.activeOrders?.Items);
      })
      .catch(console.error);
    await getCancelledCustomerOrders(pUserInfo, pOrganizationTablePrefix)
      .then((res) => {
        setCancelledOrders(res?.cancelledOrders?.Items);
      })
      .catch(console.error);
    await getConfirmedCustomerOrders(pUserInfo, pOrganizationTablePrefix)
      .then((res) => {
        setConfirmedOrders(res?.confirmedOrders?.Items);
      })
      .catch(console.error);
    await getDeliveredCustomerOrders(pUserInfo, pOrganizationTablePrefix)
      .then((res) => {
        setCompletedOrders(res?.deliveredOrders?.Items);
      })
      .catch(console.error);
  };

  const refreshUserInfo = async () => {
    await Auth.currentUserInfo()
      .then((res) => {
        setUserInfo(res);
      })
      .catch(console.error);
  };

  const refreshCustomerDetails = async () => {
    await getCustomer(organizationSettings?.organizationTablePrefix)
      .then(setCustomerDetails)
      .catch(console.error);
    await getCustomerLogo(
      organizationSettings?.organizationID,
      customerDetails?.customerID
    )
      .then(setCustomerLogo)
      .catch(console.error);
  };

  const getUserInfo = async () => await Auth.currentUserInfo();
  useEffect(() => {
    Auth.currentUserInfo()
      .then((res) => {
        setUserInfo(res);
        getOrganizationSettings(
          res?.attributes?.["custom:organizationID"]
        ).then(setOrganizationSettings);
      })
      .catch(console.error);

    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          getUserInfo().then((res) => {
            setUserInfo(res);
            getOrganizationSettings(
              res?.attributes?.["custom:organizationID"]
            ).then(setOrganizationSettings);
          });
          break;
        case "signUp":
          break;
        case "signOut":
          setUserInfo(undefined);
          break;
        case "signIn_failure":
          break;
        case "configured":
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    getCustomerLogo(
      organizationSettings?.organizationID,
      customerDetails?.customerID
    )
      .then(setCustomerLogo)
      .catch(console.error);
  }, [organizationSettings, customerDetails]);

  useEffect(() => {
    if (!organizationTablePrefix) {
      return;
    }
    refreshOrder(userInfo, organizationTablePrefix);
    getDrivers(organizationTablePrefix).then((res) =>
      setDrivers(res?.drivers?.Items)
    );
    getCustomer(organizationTablePrefix)
      .then(setCustomerDetails)
      .catch(console.error);
  }, [organizationTablePrefix, userInfo]);

  return (
    <VishcorpContext.Provider
      value={{
        userInfo,
        drivers,
        incomingOrders,
        activeOrders,
        cancelledOrders,
        confirmedOrders,
        completedOrders,
        selectedOrder,
        organizationSettings,
        customerDetails,
        customerLogo,
        setSelectedOrder,
        refreshOrder,
        refreshUserInfo,
        refreshCustomerDetails,
      }}
    >
      <VishcorpClientAuthenticator>
        <BrowserRouter>
          <MenuDrawer>
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/Settings" element={<Settings />} />
            </Routes>
          </MenuDrawer>
        </BrowserRouter>
      </VishcorpClientAuthenticator>
    </VishcorpContext.Provider>
  );
}

export default App;

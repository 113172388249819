import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import { useContext } from "react";
import { VishcorpContext } from "Context";

export default function Options(props) {
  const { userInfo } = useContext(VishcorpContext);
  const { onOptionChanged, selectedOption } = props;

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Settings
        </ListSubheader>
      }
    >
      {userInfo?.attributes !== undefined &&
      userInfo?.attributes?.["custom:isCostCenter"] === "1" ? (
        <ListItemButton
          selected={selectedOption === "Users"}
          onClick={() => {
            onOptionChanged("Users");
          }}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
      ) : (
        <></>
      )}
      {userInfo?.attributes !== undefined &&
      userInfo?.attributes?.["custom:isAdmin"] === "1" ? (
        <ListItemButton
          selected={selectedOption === "Company Settings"}
          onClick={() => {
            onOptionChanged("Company Settings");
          }}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Company Settings" />
        </ListItemButton>
      ) : (
        <></>
      )}
      {userInfo?.attributes !== undefined &&
      userInfo?.attributes?.["custom:isAdmin"] === "1" ? (
        <ListItemButton
          selected={selectedOption === "Cost Centers"}
          onClick={() => {
            onOptionChanged("Cost Centers");
          }}
        >
          <ListItemText primary="Cost Centers" />
        </ListItemButton>
      ) : (
        <></>
      )}
    </List>
  );
}

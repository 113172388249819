import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { getOrderAttachment, getOrderDeliveryAttachment } from "API";
import { Buffer } from "buffer";

export default function AttachmentsTable(props) {
  const { attachments, orderID, type } = props;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 100 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>File Name</b>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <b>Action</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments ? (
            attachments?.map((e, i) => (
              <TableRow key={i}>
                <TableCell>{String(e).slice(0, -5)}</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      switch (type) {
                        case "order":
                          getOrderAttachment(
                            orderID,
                            String(e).split("/").pop()
                          )
                            .then((res) => {
                              const buf = JSON.parse(
                                Buffer.from(res?.Body).toString()
                              );
                              let a = document.createElement("a");
                              a.href = buf?.data;
                              a.download = buf?.fileName;
                              a.click();
                            })
                            .catch(console.error);
                          break;
                        case "delivery":
                          getOrderDeliveryAttachment(
                            orderID,
                            String(e).split("/").pop()
                          )
                            .then((res) => {
                              const buf = JSON.parse(
                                Buffer.from(res?.Body).toString()
                              );
                              let a = document.createElement("a");
                              a.href = `data:image/png;base64, ${buf?.data}`;
                              a.download = `${buf?.fileName}.png`;
                              a.click();
                            })
                            .catch(console.error);
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell style={{ textAlign: "center" }} colSpan={2}>
                No Attachments
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { TextField } from "@mui/material";

export default function Email(props) {
  const {
    defaultValue,
    value,
    label,
    required,
    register,
    errors,
    setValue,
    style,
  } = props;
  return (
    <TextField
      label={label}
      fullWidth
      style={style ?? {}}
      size="small"
      {...register(label, {
        required: required ? "Required*" : false,
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Entered value does not match email format",
        },
      })}
      value={value ?? defaultValue ?? ""}
      variant="outlined"
      error={errors?.[label] && required}
      helperText={required && errors?.[label]?.message}
      onChange={(e) => {
        setValue(e?.target?.value);
      }}
      // value={value ?? ""}
    />
  );
}

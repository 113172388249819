import {
  Button,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import AlertDialog from "Components/AlertDialog";
import { VishcorpContext } from "Context";
import { useContext, useEffect, useState } from "react";
import { updateCustomerCompanySettings, updateCustomerLogo } from "./functions";

export default function CompanySettings(props) {
  const {
    customerDetails,
    refreshCustomerDetails,
    organizationSettings,
    customerLogo,
  } = useContext(VishcorpContext);
  const [customerCompanySettings, setCustomerCompanySettings] = useState(
    customerDetails?.customerCompanySettings ?? {
      companyName: "",
      // logo: "",
    }
  );
  const [pCustomerLogo, setCustomerLogo] = useState(undefined);

  const [openSaveCompanySettings, setOpenSaveCompanySettings] = useState(false);

  useEffect(() => {
    if (!customerLogo?.data) return;
    setCustomerLogo(
      `data:image/png;base64, ${
        JSON.parse(new Buffer.from(customerLogo?.data).toString())?.data
      }`
    );
  }, [customerLogo]);

  const onCompanySettingsChange = (name, e) => {
    setCustomerCompanySettings((pre) => ({
      ...pre,
      [name]: e,
    }));
  };
  const convertToBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setCustomerLogo(reader.result);
      // onCompanySettingsChange("logo", reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <div>
      <Paper
        sx={{ marginLeft: 1, padding: 2, height: "100vh", overflow: "scroll" }}
      >
        <Typography variant="h5">Company Settings</Typography>
        <div
          style={{
            height: "85vh",
            marginTop: 30,
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
          }}
        >
          <TextField
            size="small"
            value={customerCompanySettings?.companyDisplayName}
            label="Company Display Name"
            sx={{ width: "50vh" }}
            onChange={(e) => {
              onCompanySettingsChange("companyDisplayName", e?.target?.value);
            }}
          />
          <div style={{ marginTop: 30 }}>
            <FormControlLabel
              label={<b>Company Logo</b>}
              labelPlacement="start"
              style={{ marginLeft: -1 }}
              control={
                <div style={{ marginLeft: 20 }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      try {
                        convertToBase64(e?.target?.files[0]);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                </div>
              }
            />
            <br />
            {pCustomerLogo ? (
              <img
                src={pCustomerLogo}
                alt="No Logo"
                style={{ height: "120px" }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setOpenSaveCompanySettings(true);
            }}
          >
            Save Changes
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {}}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        </div>
      </Paper>
      <AlertDialog
        open={openSaveCompanySettings}
        dialogTitle="Update Company Settings?"
        handleClose={() => {
          setOpenSaveCompanySettings(false);
        }}
        handleConfirm={async () => {
          await updateCustomerCompanySettings(
            customerDetails?.customerID,
            customerCompanySettings,
            organizationSettings?.organizationTablePrefix
          );
          await updateCustomerLogo(
            organizationSettings?.organizationID,
            customerDetails?.customerID,
            pCustomerLogo
          );
          refreshCustomerDetails();
          setOpenSaveCompanySettings(false);
        }}
      />
    </div>
  );
}

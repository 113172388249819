import React, { useContext, useEffect, useState } from "react";
// import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Button,
  // Button,
  Checkbox,
  // Fab,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  // Input,
} from "@mui/material";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { TextAreaField } from "@aws-amplify/ui-react";
import { Add } from "@mui/icons-material";
// import { primaryButtonColor, primaryButtonHoverColor } from "../../constants";
// import AlertDialog from "../AlertDialog/AlertDialog";
import { Auth } from "aws-amplify";
// import OrderDetails from "../Orders/OrderDetails";
import { useForm } from "react-hook-form";
import { GAutoComplete } from "Components/GAutoComplete";
import { primaryButtonColor, primaryButtonHoverColor } from "constants";
import AlertDialog from "Components/AlertDialog";
import OrderDetails from "Components/Orders/OrderDetails";
import RenderInput from "UI/RenderInput";
import { VishcorpContext } from "Context";
import { createOrder } from "API/Orders";

function formatDateTime(time = new Date()) {
  return new Date(time).toISOString().slice(0, 19).replace("T", " ");
}

function getFormattedDate(daysForward = 0) {
  const date = new Date();
  date.setDate(date.getDate() + daysForward);
  return date.toLocaleDateString("en-GB", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
}

const hourOptions = [...Array(24).keys()].map((e) =>
  String(e).padStart(2, "0")
);
const minuteOptions = [...Array(60).keys()].map((e) =>
  String(e).padStart(2, "0")
);

const readyOptionDates = [...Array(8).keys()].map((e) => {
  let currentDate = getFormattedDate(e);
  return { label: currentDate, value: currentDate };
});

const readyOptions = [
  { label: "Now", value: "Now" },
  ...readyOptionDates,
  // { label: "Today", value: "Today" },
  // { label: "Future", value: "Future" },
];

// const serviceOptions = [
//   { label: "Standard", value: "Standard" },
//   { label: "Country", value: "Country" },
//   { label: "Urgent", value: "Urgent" },
//   { label: "Country 3 Day Delivery", value: "Country 3 Day Delivery" },
//   { label: "COD", value: "COD" },
//   { label: "Country NCA", value: "Country NCA" },
// ];

// const vehicleOptions = [
//   { label: "Sedan", value: "Sedan" },
//   { label: "Van", value: "Van" },
//   { label: "Wagon", value: "Wagon" },
//   { label: "Ute", value: "Ute" },
//   { label: "Ute", value: "Ute" },
//   { label: "Large Van", value: "Large Van" },
// ];

export default function NewOrder(props) {
  const { organizationSettings } = useContext(VishcorpContext);
  const orderSettings =
    organizationSettings?.organizationSettings?.orderSettings;
  const vehicleTypes = organizationSettings?.vehicleTypes;
  const serviceOptions = organizationSettings?.serviceOptions;
  const packageTypes = organizationSettings?.packageTypes;

  const [settingsGroup, setSettingsGroup] = useState(undefined);

  const currentHour = new Date().getHours();

  useEffect(() => {
    if (!orderSettings) {
      return;
    }
    setSettingsGroup({
      pickUp: orderSettings?.filter(
        (e) => e?.group === "Pick Up" && e?.visible && !e?.isDefault
      ),
      deliverTo: orderSettings?.filter(
        (e) => e?.group === "Deliver To" && e?.visible && !e?.isDefault
      ),
      parcelDetails: orderSettings?.filter(
        (e) => e?.group === "Parcel Details" && e?.visible && !e?.isDefault
      ),
    });
  }, [orderSettings]);

  const [pickupDetails, setPickupDetails] = useState({
    company: "",
    contact: "",
    address: "",
    city: "",
    postCode: "",
    contactPhone: "",
    country: "",
  });
  const [deliveryDetails, setDeliveryDetails] = useState({
    company: "",
    contact: "",
    address: "",
    city: "",
    postCode: "",
    contactPhone: "",
    country: "",
  });
  const [parcelDetails, setParcelDetails] = useState({
    readyTime: "Now",
    readyHour: "",
    readyHourMinutes: "",
    serviceType: "",
    vehicleType: "",
    attachments: "",
    specialInstructions: "",
    roundTrip: false,
    reference: "",
    packageType: "",
  });
  const [confirmOrder, setConfirmOrder] = useState(false);
  const resetForm = () => {
    setPickupDetails({
      company: "",
      contact: "",
      address: "",
      city: "",
      postCode: "",
      contactPhone: "",
      country: "",
    });
    setDeliveryDetails({
      company: "",
      contact: "",
      address: "",
      city: "",
      postCode: "",
      contactPhone: "",
      country: "",
    });
    setParcelDetails({
      readyTime: "Now",
      readyHour: "",
      readyHourMinutes: "",
      weight: "",
      pieces: "",
      serviceType: "",
      vehicleType: "",
      attachments: "",
      specialInstructions: "",
      roundTrip: false,
      reference: "",
      packageType: "",
    });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [isFilesUploading, setIsFilesUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [orderAttachments, setOrderAttachments] = useState([]);
  const [pickupOptions, setPickupOptions] = useState(undefined);
  const [deliveryOptions, setDeliveryOptions] = useState(undefined);
  //Pickup
  //Company
  //Contact
  //Address
  //City
  //Postal Code
  //Contact Phone

  //Delivery
  //Company
  //Contact
  //Address
  //City
  //Postal Code
  //Contact Phone

  //Details
  //ParcelDetails - Ready - ['Now']
  //ServiceType - ['Standard']
  //VehicleType - ['Sedan']
  //PackageType - Quantity - Weight
  //Special Instructions - Text
  //RoundTrip - Bool
  //Attachments
  //Reference

  const handleConfirmOrder = async () => {
    if (isFilesUploading || !organizationSettings?.organizationID) {
      return;
    }
    const userInfo = await Auth.currentUserInfo();

    let orderReadyTime;

    if (parcelDetails.readyTime === "Now") {
      orderReadyTime = formatDateTime();
    } else {
      let dateString = parcelDetails.readyTime;
      let [day, month, year] = dateString.split("/");
      const dateObj = new Date(
        +year,
        +month - 1,
        +day,
        parseInt(parcelDetails.readyHour),
        parseInt(parcelDetails.readyHourMinutes)
      );
      orderReadyTime = formatDateTime(dateObj);
    }

    await createOrder(
      userInfo,
      deliveryDetails,
      pickupDetails,
      { ...parcelDetails, orderReadyTime },
      orderAttachments,
      organizationSettings
    ).then((res) => console.log("response here", res));

    resetForm();
    setConfirmOrder(false);
  };

  const handleFileDelete = (index) => {
    setOrderAttachments((preVal) => preVal?.filter((e, i) => i !== index));
  };

  const onDetailsChange = (field, value, callback) => {
    callback((preVal) => ({
      ...preVal,
      [field]: value,
    }));
  };
  const setConfirm = () => {
    setConfirmOrder(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginLeft: 5 }}>
          <FormLabel style={{ fontSize: "2rem" }}>New Order</FormLabel>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: 20, margin: 5 }}>
            <FormLabel>Pick Up</FormLabel>
            <br />
            <TextField
              error={errors?.pickupCompany && true}
              helperText={errors?.pickupCompany?.message}
              {...register("pickupCompany", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="Company"
              variant="outlined"
              value={pickupDetails?.company}
              onChange={(e) =>
                onDetailsChange("company", e?.target?.value, setPickupDetails)
              }
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            <TextField
              error={errors?.pickupContact && true}
              helperText={errors?.pickupContact?.message}
              {...register("pickupContact", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="Contact"
              variant="outlined"
              value={pickupDetails?.contact}
              onChange={(e) =>
                onDetailsChange("contact", e?.target?.value, setPickupDetails)
              }
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            <Autocomplete
              // error={errors?.pickupAddress && true}
              // helperText={errors?.pickupAddress?.message}
              size="small"
              id="outlined-basic"
              key="pickupAddress"
              label="Address"
              variant="outlined"
              value={pickupDetails?.address}
              onKeyDown={(event, value) => {
                switch (event?.key) {
                  case "Enter":
                    const pSelectedOption = pickupOptions.find(
                      (e) => e?.label === event?.target?.value
                    );
                    if (pSelectedOption?.data?.place_id) {
                      new window.google.maps.places.PlacesService(
                        event?.target
                      ).getDetails(
                        { placeId: pSelectedOption?.data?.place_id },
                        (place, status) => {
                          if (status === "OK") {
                            setPickupDetails((preVal) => {
                              return {
                                ...preVal,
                                country: place?.address_components?.find((e) =>
                                  e?.types?.includes("country")
                                )?.long_name,
                                postCode: place?.address_components?.find((e) =>
                                  e?.types?.includes("postal_code")
                                )?.long_name,
                                latitude: place?.geometry.location.lat(),
                                longitude: place?.geometry.location.lng(),
                                city: place?.address_components?.find((e) =>
                                  e?.types?.includes("locality")
                                )?.long_name,
                              };
                            });
                            setValue(
                              "pickupCity",
                              place?.address_components?.find((e) =>
                                e?.types?.includes("locality")
                              )?.long_name
                            );
                            setValue(
                              "pickupCountry",
                              place?.address_components?.find((e) =>
                                e?.types?.includes("country")
                              )?.long_name
                            );
                            setValue(
                              "pickupPostCode",
                              place?.address_components?.find((e) =>
                                e?.types?.includes("postal_code")
                              )?.long_name
                            );
                          }
                        }
                      );
                    }
                    onDetailsChange(
                      "address",
                      pSelectedOption?.label,
                      setPickupDetails
                    );
                    break;
                  default:
                    break;
                }
              }}
              onChange={(event, value) => {
                if (event?.target?.innerHTML && value?.data?.place_id) {
                  new window.google.maps.places.PlacesService(
                    event?.target
                  ).getDetails(
                    { placeId: value?.data?.place_id },
                    (place, status) => {
                      if (status === "OK") {
                        setPickupDetails((preVal) => {
                          return {
                            ...preVal,
                            country: place?.address_components?.find((e) =>
                              e?.types?.includes("country")
                            )?.long_name,
                            postCode: place?.address_components?.find((e) =>
                              e?.types?.includes("postal_code")
                            )?.long_name,
                            latitude: place?.geometry.location.lat(),
                            longitude: place?.geometry.location.lng(),
                            city: place?.address_components?.find((e) =>
                              e?.types?.includes("locality")
                            )?.long_name,
                          };
                        });
                        setValue(
                          "pickupCity",
                          place?.address_components?.find((e) =>
                            e?.types?.includes("locality")
                          )?.long_name
                        );
                        setValue(
                          "pickupCountry",
                          place?.address_components?.find((e) =>
                            e?.types?.includes("country")
                          )?.long_name
                        );
                        setValue(
                          "pickupPostCode",
                          place?.address_components?.find((e) =>
                            e?.types?.includes("postal_code")
                          )?.long_name
                        );
                      }
                    }
                  );
                }
                onDetailsChange("address", value?.label, setPickupDetails);
              }}
              options={pickupOptions ?? []}
              filterSelectedOptions
              autoComplete
              filterOptions={(x) => x}
              style={{ width: "100%", marginBottom: 15 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors?.pickupAddress && true}
                  helperText={errors?.pickupAddress?.message}
                  {...register("pickupAddress", {
                    required: "Required*",
                  })}
                  label="Address"
                  onChange={(e) => {
                    GAutoComplete(e?.target?.value)
                      .then((res) => {
                        // console.log("Suggestions===>", res);
                        setPickupOptions(
                          res?.map((e) => ({ label: e?.description, data: e }))
                        );
                      })
                      .catch(console.error);
                  }}
                />
              )}
            />
            <br />
            <TextField
              error={errors?.pickupCity && true}
              helperText={errors?.pickupCity?.message}
              {...register("pickupCity", {
                required: "Required*",
                validate: (value) => {
                  return value !== undefined && value !== "";
                },
              })}
              size="small"
              id="outlined-basic"
              label="City"
              variant="outlined"
              value={pickupDetails?.city}
              onChange={(e) =>
                onDetailsChange("city", e?.target?.value, setPickupDetails)
              }
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            <TextField
              error={errors?.pickupPostCode && true}
              helperText={errors?.pickupPostCode?.message}
              {...register("pickupPostCode", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="Post Code"
              variant="outlined"
              value={pickupDetails?.postCode}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (
                  (e.target.value === "" || regex.test(e.target.value)) &&
                  e?.target?.value?.length < 5 &&
                  e?.target?.value?.length > -1
                ) {
                  onDetailsChange(
                    "postCode",
                    e?.target?.value,
                    setPickupDetails
                  );
                }
              }}
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            <TextField
              error={errors?.pickupContactPhone && true}
              helperText={errors?.pickupContactPhone?.message}
              {...register("pickupContactPhone", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="Contact Phone"
              variant="outlined"
              value={pickupDetails?.contactPhone}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                if (onlyNums.length < 10) {
                  onDetailsChange("contactPhone", onlyNums, setPickupDetails);
                } else if (onlyNums.length === 10) {
                  const number = onlyNums.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "($1) $2-$3"
                  );
                  onDetailsChange("contactPhone", number, setPickupDetails);
                }
              }}
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            <TextField
              error={errors?.pickupCountry && true}
              helperText={errors?.pickupCountry?.message}
              {...register("pickupCountry", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="Country"
              variant="outlined"
              value={pickupDetails?.country}
              onChange={(e) =>
                onDetailsChange("country", e?.target?.value, setPickupDetails)
              }
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            {settingsGroup?.pickUp &&
              settingsGroup?.pickUp?.map((e) => (
                <div style={{ marginBottom: 15 }} key={e?.id}>
                  <RenderInput
                    value={pickupDetails?.[e?.id]}
                    setValue={(val) => {
                      onDetailsChange(e?.id, val, setPickupDetails);
                      // updateCustomerDetails(e?.id, val);
                    }}
                    register={register}
                    errors={errors}
                    type={e?.type}
                    required={e?.required}
                    label={e?.name}
                    id={e?.id}
                    key={e?.id}
                    control={control}
                    options={e?.options ?? []}
                    defaultValue={e?.defaultValue}
                  />
                </div>
              ))}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: 20, margin: 5 }}>
            <FormLabel>Deliver To</FormLabel>
            <br />
            <TextField
              error={errors?.deliveryCompany && true}
              helperText={errors?.deliveryCompany?.message}
              {...register("deliveryCompany", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="Company"
              variant="outlined"
              value={deliveryDetails?.company}
              onChange={(e) =>
                onDetailsChange("company", e?.target?.value, setDeliveryDetails)
              }
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            <TextField
              error={errors?.deliveryContact && true}
              helperText={errors?.deliveryContact?.message}
              {...register("deliveryContact", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="Contact"
              variant="outlined"
              value={deliveryDetails?.contact}
              onChange={(e) =>
                onDetailsChange("contact", e?.target?.value, setDeliveryDetails)
              }
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            <Autocomplete
              // error={errors?.deliveryAddress && true}
              // helperText={errors?.deliveryAddress?.message}
              size="small"
              id="outlined-basic"
              label="Address"
              variant="outlined"
              value={deliveryDetails?.address}
              options={deliveryOptions ?? []}
              onKeyDown={(event, value) => {
                switch (event?.key) {
                  case "Enter":
                    const pSelectedOption = deliveryOptions.find(
                      (e) => e?.label === event?.target?.value
                    );
                    if (pSelectedOption?.data?.place_id) {
                      new window.google.maps.places.PlacesService(
                        event?.target
                      ).getDetails(
                        { placeId: pSelectedOption?.data?.place_id },
                        (place, status) => {
                          if (status === "OK") {
                            setDeliveryDetails((preVal) => {
                              return {
                                ...preVal,
                                country: place?.address_components?.find((e) =>
                                  e?.types?.includes("country")
                                )?.long_name,
                                postCode: place?.address_components?.find((e) =>
                                  e?.types?.includes("postal_code")
                                )?.long_name,
                                latitude: place?.geometry.location.lat(),
                                longitude: place?.geometry.location.lng(),
                                city: place?.address_components?.find((e) =>
                                  e?.types?.includes("locality")
                                )?.long_name,
                              };
                            });
                            setValue(
                              "deliveryCity",
                              place?.address_components?.find((e) =>
                                e?.types?.includes("locality")
                              )?.long_name
                            );
                            setValue(
                              "deliveryCountry",
                              place?.address_components?.find((e) =>
                                e?.types?.includes("country")
                              )?.long_name
                            );
                            setValue(
                              "deliveryPostCode",
                              place?.address_components?.find((e) =>
                                e?.types?.includes("postal_code")
                              )?.long_name
                            );
                          }
                        }
                      );
                    }
                    onDetailsChange(
                      "address",
                      pSelectedOption?.label,
                      setDeliveryDetails
                    );
                    break;
                  default:
                    break;
                }
              }}
              onChange={(event, value) => {
                if (event?.target?.innerHTML && value?.data?.place_id) {
                  new window.google.maps.places.PlacesService(
                    event?.target
                  ).getDetails(
                    { placeId: value?.data?.place_id },
                    (place, status) => {
                      if (status === "OK") {
                        setDeliveryDetails((preVal) => {
                          return {
                            ...preVal,
                            country: place?.address_components?.find((e) =>
                              e?.types?.includes("country")
                            )?.long_name,
                            postCode: place?.address_components?.find((e) =>
                              e?.types?.includes("postal_code")
                            )?.long_name,
                            latitude: place?.geometry.location.lat(),
                            longitude: place?.geometry.location.lng(),
                            city: place?.address_components?.find((e) =>
                              e?.types?.includes("locality")
                            )?.long_name,
                          };
                        });
                        setValue(
                          "deliveryCity",
                          place?.address_components?.find((e) =>
                            e?.types?.includes("locality")
                          )?.long_name
                        );
                        setValue(
                          "deliveryCountry",
                          place?.address_components?.find((e) =>
                            e?.types?.includes("country")
                          )?.long_name
                        );
                        setValue(
                          "deliveryPostCode",
                          place?.address_components?.find((e) =>
                            e?.types?.includes("postal_code")
                          )?.long_name
                        );
                      }
                    }
                  );
                }
                onDetailsChange("address", value?.label, setDeliveryDetails);
              }}
              filterSelectedOptions
              autoComplete
              filterOptions={(x) => x}
              style={{ width: "100%", marginBottom: 15 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors?.deliveryAddress && true}
                  helperText={errors?.deliveryAddress?.message}
                  {...register("deliveryAddress", {
                    required: "Required*",
                  })}
                  label="Address"
                  onChange={(e) => {
                    GAutoComplete(e?.target?.value)
                      .then((res) => {
                        // console.log("Suggestions===>", res);
                        setDeliveryOptions(
                          res?.map((e) => ({ label: e?.description, data: e }))
                        );
                      })
                      .catch(console.error);
                  }}
                />
              )}
            />
            <br />
            <TextField
              error={errors?.deliveryCity && true}
              helperText={errors?.deliveryCity?.message}
              {...register("deliveryCity", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="City"
              variant="outlined"
              value={deliveryDetails?.city}
              onChange={(e) =>
                onDetailsChange("city", e?.target?.value, setDeliveryDetails)
              }
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            <TextField
              error={errors?.deliveryPostCode && true}
              helperText={errors?.deliveryPostCode?.message}
              {...register("deliveryPostCode", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="Post Code"
              variant="outlined"
              value={deliveryDetails?.postCode}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (
                  (e.target.value === "" || regex.test(e.target.value)) &&
                  e?.target?.value?.length < 5 &&
                  e?.target?.value?.length > -1
                ) {
                  onDetailsChange(
                    "postCode",
                    e?.target?.value,
                    setDeliveryDetails
                  );
                }
              }}
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            <TextField
              error={errors?.deliveryContactPhone && true}
              helperText={errors?.deliveryContactPhone?.message}
              {...register("deliveryContactPhone", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="Contact Phone"
              variant="outlined"
              value={deliveryDetails?.contactPhone}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                if (onlyNums.length < 10) {
                  onDetailsChange("contactPhone", onlyNums, setDeliveryDetails);
                } else if (onlyNums.length === 10) {
                  const number = onlyNums.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "($1) $2-$3"
                  );
                  onDetailsChange("contactPhone", number, setDeliveryDetails);
                }
              }}
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            <TextField
              error={errors?.deliveryCountry && true}
              helperText={errors?.deliveryCountry?.message}
              {...register("deliveryCountry", {
                required: "Required*",
              })}
              size="small"
              id="outlined-basic"
              label="Country"
              variant="outlined"
              value={deliveryDetails?.country}
              onChange={(e) =>
                onDetailsChange("country", e?.target?.value, setDeliveryDetails)
              }
              style={{ width: "100%", marginBottom: 15 }}
            />
            <br />
            {settingsGroup?.deliverTo &&
              settingsGroup?.deliverTo?.map((e) => (
                <div style={{ marginBottom: 15 }} key={e?.id}>
                  <RenderInput
                    value={deliveryDetails?.[e?.id]}
                    setValue={(val) => {
                      onDetailsChange(e?.id, val, setDeliveryDetails);
                    }}
                    register={register}
                    errors={errors}
                    type={e?.type}
                    required={e?.required}
                    label={e?.name}
                    id={e?.id}
                    key={e?.id}
                    control={control}
                    options={e?.options ?? []}
                    defaultValue={e?.defaultValue}
                  />
                </div>
              ))}
          </Paper>
          {/* <Item>xs=6</Item> */}
        </Grid>
        <Grid container justifyContent="center">
          <Paper
            style={{ padding: 20, margin: 5 }}
            // fullWidth
          >
            <FormLabel>Parcel Details</FormLabel>
            <Grid
              container
              sx={{ flexGrow: 1, marginTop: 1 }}
              // xs={12}
              spacing={2}
            >
              <Grid item xs={4}>
                {/* <FormLabel>Parcel Details</FormLabel> */}
                <br />
                <br />
                <FormControl
                  sx={{ m: 0, minWidth: 120 }}
                  size="small"
                  fullWidth
                >
                  <InputLabel>Ready</InputLabel>
                  <Select
                    error={errors?.parcelReadyTime && true}
                    {...register("parcelReadyTime", {
                      required: "Required*",
                    })}
                    label="Ready"
                    onChange={(e) => {
                      if (e.target.value === "Now") {
                        onDetailsChange("readyHour", "", setParcelDetails);
                        onDetailsChange(
                          "readyHourMinutes",
                          "",
                          setParcelDetails
                        );
                      }
                      onDetailsChange(
                        "readyTime",
                        e?.target?.value,
                        setParcelDetails
                      );
                    }}
                    value={parcelDetails?.readyTime}
                  >
                    {readyOptions?.map((e, i) => {
                      return (
                        <MenuItem value={e?.value} key={i}>
                          {e?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      marginTop: 1,
                      display: "flex",
                      alignItems: "end",
                    }}
                  >
                    <Grid item xs={6}>
                      {!["Now"].includes(parcelDetails?.readyTime) ? (
                        <div style={{ display: "flex" }}>
                          <FormControl sx={{ minWidth: "100px" }} size="small">
                            <InputLabel>Hours</InputLabel>
                            <Select
                              label="Hours"
                              // size="small"
                              error={
                                errors?.parcelReadyHour &&
                                !["Now"].includes(parcelDetails?.readyTime) &&
                                true
                              }
                              {...register("parcelReadyHour", {
                                required: "Required*",
                              })}
                              onChange={(e) => {
                                onDetailsChange(
                                  "readyHour",
                                  e?.target?.value,
                                  setParcelDetails
                                );
                              }}
                              value={parcelDetails?.readyHour}
                            >
                              {hourOptions.map((e, i) => (
                                <MenuItem
                                  value={e}
                                  key={i}
                                  disabled={e < currentHour}
                                >
                                  {e}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            sx={{ minWidth: "100px", marginLeft: 2 }}
                            size="small"
                          >
                            <InputLabel>Minutes</InputLabel>
                            <Select
                              label="Minutes"
                              error={
                                errors?.parcelReadyMinute &&
                                !["Now"].includes(parcelDetails?.readyTime) &&
                                true
                              }
                              {...register("parcelReadyMinute", {
                                required: "Required*",
                              })}
                              onChange={(e) => {
                                onDetailsChange(
                                  "readyHourMinutes",
                                  e?.target?.value,
                                  setParcelDetails
                                );
                              }}
                              value={parcelDetails?.readyHourMinutes}
                            >
                              {minuteOptions.map((e, i) => (
                                <MenuItem value={e} key={i}>
                                  {e}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </FormControl>
                <br />
                <br />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      // error={errors?.reference && true}
                      // helperText={errors?.reference?.message}
                      // {...register("reference", {
                      //   required: "Required*",
                      // })}
                      label="Pieces"
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                        if (onlyNums.length <= 5) {
                          onDetailsChange("pieces", onlyNums, setParcelDetails);
                        }
                      }}
                      value={parcelDetails?.pieces}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      // error={errors?.reference && true}
                      // helperText={errors?.reference?.message}
                      // {...register("reference", {
                      //   required: "Required*",
                      // })}
                      label="Weight (KG)"
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                        if (onlyNums.length <= 5) {
                          onDetailsChange("weight", onlyNums, setParcelDetails);
                        }
                      }}
                      value={parcelDetails?.weight}
                    />
                  </Grid>
                </Grid>
                <br />
                <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                  <InputLabel>Package Type</InputLabel>
                  <Select
                    error={errors?.packageType && true}
                    {...register("packageType", {
                      required: "Required*",
                    })}
                    label="Package Type"
                    onChange={(e) => {
                      onDetailsChange(
                        "packageType",
                        e?.target?.value,
                        setParcelDetails
                      );
                    }}
                    value={parcelDetails?.packageType}
                  >
                    {packageTypes?.map((e, i) => {
                      return (
                        <MenuItem value={e?.name} key={i}>
                          {e?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={6} sx={{ paddingLeft: 1 }}>
                    <FormLabel>Service Type</FormLabel>
                    <br />
                    <br />
                    <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                      <Select
                        error={errors?.parcelServiceType && true}
                        {...register("parcelServiceType", {
                          required: "Required*",
                        })}
                        onChange={(e) => {
                          onDetailsChange(
                            "serviceType",
                            e?.target?.value,
                            setParcelDetails
                          );
                        }}
                        value={parcelDetails?.serviceType}
                      >
                        {serviceOptions?.map((e, i) => {
                          return (
                            <MenuItem value={e?.name} key={i}>
                              {e?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sx={{ paddingLeft: 1 }}>
                    <FormLabel>Vehicle Type</FormLabel>
                    <br />
                    <br />
                    <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                      <Select
                        error={errors?.parcelVehicleType && true}
                        {...register("parcelVehicleType", {
                          required: "Required*",
                        })}
                        onChange={(e) => {
                          onDetailsChange(
                            "vehicleType",
                            e?.target?.value,
                            setParcelDetails
                          );
                        }}
                        value={parcelDetails?.vehicleType}
                      >
                        {vehicleTypes?.map((e, i) => {
                          return (
                            <MenuItem value={e?.name} key={i}>
                              {e?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingLeft: 1, paddingTop: 1 }}>
                    <input
                      type="file"
                      onChange={(event) => {
                        setIsFilesUploading(true);
                        let fileReader = new FileReader();
                        fileReader.readAsDataURL(event.target.files[0]);
                        fileReader.onload = (e) => {
                          setOrderAttachments((preVal) => [
                            ...(preVal ?? []),
                            {
                              data: e.target.result,
                              fileName: event.target.files?.[0]?.name,
                              fileType: event.target.files?.[0]?.type,
                            },
                          ]);
                          setIsFilesUploading(false);
                          setFileUploaded(event.target.files?.[0]?.name);
                        };
                      }}
                    />
                    {orderAttachments ? (
                      <div
                        style={{
                          flex: 1,
                          width: "100%",
                          height: "20vh",
                          overflow: "scroll",
                        }}
                      >
                        <List dense={true}>
                          {orderAttachments?.map((orderAttachment, i) => (
                            <ListItem
                              key={i}
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => {
                                    handleFileDelete(i);
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              }
                            >
                              <ListItemText
                                primary={orderAttachment?.fileName}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                {/* <FormLabel>Special Instructions</FormLabel> */}
                <TextAreaField
                  id="outlined-basic"
                  onChange={(e) => {
                    onDetailsChange(
                      "specialInstructions",
                      e?.target?.value,
                      setParcelDetails
                    );
                  }}
                  label="Special Instructions"
                  variant="outlined"
                  style={{ width: "100%", marginTop: 15 }}
                  // fullWidth
                  value={parcelDetails?.specialInstructions}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={parcelDetails?.roundTrip}
                      onChange={(e) => {
                        onDetailsChange(
                          "roundTrip",
                          !parcelDetails?.roundTrip,
                          setParcelDetails
                        );
                      }}
                    />
                  }
                  label="Round Trip"
                  size="small"
                />
                <br />
                <TextField
                  error={errors?.reference && true}
                  helperText={errors?.reference?.message}
                  {...register("reference", {
                    required: "Required*",
                  })}
                  label="Reference"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    onDetailsChange(
                      "reference",
                      e?.target?.value,
                      setParcelDetails
                    );
                  }}
                  value={parcelDetails?.reference}
                />
              </Grid>
              {settingsGroup?.parcelDetails &&
                settingsGroup?.parcelDetails?.map((e, i) => (
                  <Grid item xs={4} key={e?.id}>
                    <RenderInput
                      value={parcelDetails?.[e?.id]}
                      setValue={(val) => {
                        onDetailsChange(e?.id, val, setParcelDetails);
                      }}
                      register={register}
                      errors={errors}
                      type={e?.type}
                      required={e?.required}
                      label={e?.name}
                      id={e?.id}
                      key={e?.id}
                      control={control}
                      options={e?.options ?? []}
                      defaultValue={e?.defaultValue}
                    />
                  </Grid>
                ))}
            </Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <Button
                sx={{
                  borderRadius: 0,
                  backgroundColor: primaryButtonColor,
                  "&:hover": {
                    backgroundColor: primaryButtonHoverColor,
                  },
                }}
                onClick={handleSubmit(setConfirm)}
                variant="contained"
                aria-label="add"
              >
                <Add sx={{ mr: 1 }} />
                Create Order
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <AlertDialog
        open={confirmOrder}
        handleClose={() => {
          setConfirmOrder(false);
        }}
        handleConfirm={handleConfirmOrder}
        dialogTitle="Confirm Order"
        dialogContent={
          <div>
            Are you sure you want to confirm order with Current Details?
            <br />
            <br />
            <OrderDetails
              selectedOrder={{
                orderTimestamp: new Date().toISOString(),
                postCode: deliveryDetails?.postCode,
                pickupPostCode: pickupDetails?.postCode,
                pickupDetails,
                deliveryDetails,
                parcelDetails,
              }}
            />
          </div>
        }
      />
      <Snackbar
        open={fileUploaded ? true : false}
        autoHideDuration={8000}
        onClose={() => {
          setFileUploaded(undefined);
        }}
        message={fileUploaded ? `File ${fileUploaded} Uploaded.` : ""}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              setFileUploaded(undefined);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
}

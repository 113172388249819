import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Controller } from "react-hook-form";
import { FormHelperText } from "@mui/material";

export default function CustomRadio(props) {
  const { control, errors, required, label, options, value, setValue } = props;

  return (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel>
      <Controller
        control={control}
        name={label}
        rules={{ required: required ? "Required*" : false }}
        render={({ field }) => {
          return (
            <RadioGroup
              row
              {...field}
              value={value ?? ""}
              onChange={(e) => {
                field?.onChange(e);
                setValue(e?.target?.value);
              }}
            >
              {options &&
                options?.map((e, i) => (
                  <FormControlLabel
                    key={`${e}${i}`}
                    value={e}
                    control={<Radio />}
                    label={e}
                  />
                ))}
            </RadioGroup>
          );
        }}
      />
      {errors?.[label] && required && (
        <FormHelperText sx={{ mt: -1 }} error>
          {errors?.[label]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
}

import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  FormControl,
  Grid,
  // , InputLabel
  MenuItem,
  Select,
} from "@mui/material";
import { VishcorpContext } from "Context";
import { AttachFile } from "@mui/icons-material";
import OrderAttachments from "./OrderAttachments";
import AlertDialog from "Components/AlertDialog";

export default function EditOrder(props) {
  const { drivers } = useContext(VishcorpContext);
  const {
    selectedOrder,
    onStatusChange,
    // , onDriverAssigned
  } = props;
  const [orderStatus, setOrderStatus] = useState(selectedOrder?.orderStatus);
  // const [selectedDriver, setSelectedDriver] = useState(
  // selectedOrder?.assignedDriver ?? ""
  const selectedDriver = selectedOrder?.assignedDriver ?? "";
  // );
  const [openAttachments, setOpenAttachments] = useState(false);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 100 }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Property</b>
            </TableCell>
            <TableCell>
              <b>Value</b>
            </TableCell>
          </TableRow>
        </TableHead>
        {selectedOrder && (
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <b>Order ID</b>
              </TableCell>
              <TableCell>{selectedOrder?.orderID}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <b>Order Time</b>
              </TableCell>
              <TableCell>{selectedOrder?.orderTimestamp}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <b>Pickup At</b>
              </TableCell>
              <TableCell>{selectedOrder?.pickupDetails?.address}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <b>Delivery At</b>
              </TableCell>
              <TableCell>{selectedOrder?.deliveryDetails?.address}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <b>Attachments</b>
              </TableCell>
              <TableCell>
                <Button
                  aria-label="View File Attachments"
                  variant="outlined"
                  startIcon={<AttachFile />}
                  onClick={() => setOpenAttachments(true)}
                >
                  View Attachments
                </Button>
              </TableCell>
            </TableRow>
            {orderStatus === "Active" && (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Assigned Driver</b>
                </TableCell>
                <TableCell>
                  <FormControl
                    sx={{ m: 0, minWidth: 120 }}
                    size="small"
                    fullWidth
                  >
                    {/* <InputLabel>Driver</InputLabel> */}
                    {drivers?.find((e) => e?.driverNumber === selectedDriver)
                      ?.driverName ?? ""}
                    {/* <Select
                      label="Driver"
                      fullWidth
                      onChange={(e) => {
                        setSelectedDriver(e?.target?.value);
                        onDriverAssigned(e?.target?.value);
                      }}
                      value={selectedDriver}
                    >
                      <MenuItem value={`Not Assinged`} key={"0"}>
                        {`Not Assinged`}
                      </MenuItem>
                      {drivers?.map((e) => (
                        <MenuItem value={e?.driverNumber} key={e?.driverNumber}>
                          {e?.driverName}
                        </MenuItem>
                      ))}
                    </Select> */}
                  </FormControl>
                </TableCell>
              </TableRow>
            )}
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                {selectedDriver ? (
                  orderStatus
                ) : (
                  <Select
                    value={orderStatus}
                    fullWidth
                    onChange={(e) => {
                      setOrderStatus(e?.target?.value);
                      onStatusChange(e?.target?.value);
                    }}
                  >
                    {/* <MenuItem value="Incoming">Incoming</MenuItem> */}
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Cancel">Cancel</MenuItem>
                  </Select>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <AlertDialog
        open={openAttachments}
        handleClose={() => {
          setOpenAttachments(false);
        }}
        customControls={false}
        dialogContent={
          <Grid sx={{ position: "static" }}>
            <OrderAttachments orderID={selectedOrder?.orderID} />
          </Grid>
        }
      />
    </TableContainer>
  );
}

import { TextField } from "@mui/material";

export default function Text(props) {
  const { defaultValue, label, required, register, errors, setValue } =
    props;
  return (
    <TextField
      label={label}
      fullWidth
      defaultValue={defaultValue}
      size="small"
      {...register(label, {
        required: required ? "Required*" : false,
      })}
      variant="outlined"
      error={errors?.[label] && required}
      helperText={required && errors?.[label]?.message}
      onChange={(e) => {
        setValue(e?.target?.value);
      }}
      // value={value ?? ""}
    />
  );
}

import {
  Alert,
  Button,
  // Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from "@mui/material";
import { VishcorpContext } from "Context";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Phone from "UI/Input/Phone";
import { addCostCenterUser } from "./functions";

export default function AddUser(props) {
  const { userInfo, organizationSettings, refreshCustomerDetails } =
    useContext(VishcorpContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { handleClose } = props;
  const [costCenterUserDetails, setCostCenterUserDetails] = useState({
    userName: "",
    email: "",
    invoiceType: "together",
    password: "",
    phone: "",
    // isGlobalUser: false,
  });
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  return (
    <div style={{ marginTop: 10 }}>
      <TextField
        error={errors?.["userName"] && true}
        helperText={errors?.["userName"]?.message}
        {...register("userName", {
          required: "Required*",
        })}
        fullWidth
        size="small"
        label="Name"
        value={costCenterUserDetails?.userName}
        onChange={(e) => {
          setCostCenterUserDetails((pre) => ({
            ...pre,
            userName: e?.target?.value,
          }));
        }}
      />
      <TextField
        error={errors?.["email"] && true}
        helperText={errors?.["email"]?.message}
        {...register("email", {
          required: "Required*",
        })}
        sx={{ marginTop: 2 }}
        fullWidth
        size="small"
        label="Email"
        value={costCenterUserDetails?.email}
        onChange={(e) => {
          setCostCenterUserDetails((pre) => ({
            ...pre,
            email: e?.target?.value,
          }));
        }}
      />
      <Phone
        style={{ marginTop: 10 }}
        register={register}
        errors={errors}
        value={costCenterUserDetails?.phone}
        setValue={(e) => {
          setCostCenterUserDetails((pre) => ({
            ...pre,
            phone: e,
          }));
        }}
        label={"Phone"}
        required={true}
      />
      <FormControl
        sx={{ marginTop: 2 }}
        error={errors?.["invoiceType"] && true}
        {...register("invoiceType", {
          required: "Required*",
        })}
      >
        <FormLabel>Invoice</FormLabel>
        <RadioGroup
          row
          value={costCenterUserDetails?.invoiceType}
          onChange={(e) =>
            setCostCenterUserDetails((pre) => ({
              ...pre,
              invoiceType: e?.target?.value,
            }))
          }
        >
          <FormControlLabel
            control={<Radio />}
            value="together"
            label="Together"
          />
          <FormControlLabel
            control={<Radio />}
            value="seperate"
            label="Seperate"
          />
        </RadioGroup>
      </FormControl>
      <br />

      {/* <FormControlLabel
        control={
          <Checkbox
            checked={costCenterUserDetails?.isGlobalUser}
            onChange={(e) => {
              setCostCenterUserDetails((pre) => ({
                ...pre,
                isGlobalUser: !pre?.isGlobalUser,
              }));
            }}
          />
        }
        value="isGlobalUser"
        label="Global User"
      /> */}
      <TextField
        style={{ marginTop: 10 }}
        error={errors?.["password"] && true}
        helperText={errors?.["password"]?.message}
        {...register("password", {
          required: "Required*",
        })}
        fullWidth
        size="small"
        type="password"
        label="Password"
        value={costCenterUserDetails?.password}
        onChange={(e) => {
          setCostCenterUserDetails((pre) => ({
            ...pre,
            password: e?.target?.value,
          }));
        }}
      />
      <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ marginRight: 5 }}
          onClick={handleSubmit(async () => {
            const addCCURes = await addCostCenterUser(
              userInfo?.attributes?.["custom:customerID"],
              userInfo?.attributes?.["custom:costCenterID"],
              costCenterUserDetails,
              userInfo?.attributes?.["custom:organizationID"],
              organizationSettings?.organizationTablePrefix
            );
            if (
              String(addCCURes?.statusCode) === "400" ||
              addCCURes?.message !== undefined
            ) {
              setErrorMessage(addCCURes);
              setOpenAlertMessage(true);
            } else {
              await refreshCustomerDetails();
              handleClose();
            }
          })}
        >
          Add
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            handleClose();
          }}
          color="error"
        >
          Cancel
        </Button>
      </div>
      <Snackbar
        open={openAlertMessage}
        autoHideDuration={6000}
        onClose={() => {
          setOpenAlertMessage(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenAlertMessage(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {`Error Code: ${errorMessage?.code}. ${errorMessage?.message}`}
        </Alert>
      </Snackbar>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Auth } from "aws-amplify";

export default function OrderDetails(props) {
  const { selectedOrder } = props;
  const [userInfo, setUserInfo] = useState(undefined);

  useEffect(() => {
    Auth.currentUserInfo().then((res) => setUserInfo(res));
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 100 }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Property</b>
            </TableCell>
            <TableCell>
              <b>Value</b>
            </TableCell>
          </TableRow>
        </TableHead>
        {selectedOrder && (
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <b>Orderer</b>
              </TableCell>
              <TableCell>{userInfo?.attributes?.email}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <b>Order Time</b>
              </TableCell>
              <TableCell>{selectedOrder?.orderTimestamp}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <b>Pickup At</b>
              </TableCell>
              <TableCell>{selectedOrder?.pickupDetails?.address}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <b>Delivery At</b>
              </TableCell>
              <TableCell>{selectedOrder?.deliveryDetails?.address}</TableCell>
            </TableRow>
            {/* <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell><b>Status</b></TableCell>
              <TableCell>{selectedOrder?.orderStatus}</TableCell>
            </TableRow> */}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

import { Auth } from "aws-amplify";

export const getOrganizationSettings = async (organizationID) => {
  if (!organizationID) {
    return;
  }
  try {
    const pOrganizationSettings = await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "GET_ORGANIZATION_SETTINGS",
          organizationID,
        }),
      }
    )
      .then((res) => res.json())
      .catch(console.error);
    return pOrganizationSettings?.response?.Items?.[0];
  } catch (error) {
    return error;
  }
};

export const addAttribute = async (
  settingType,
  settingDetails,
  organizationID
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "NEW_ORGANIZATION_SETTING_ATTRIBUTE",
        organizationID,
        settingDetails,
        settingType,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => {
      // console.log(res);
    })
    .catch(console.error);
};

export const deleteAttribtue = async (
  settingType,
  deleteItem,
  organizationID
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "DELETE_ORGANIZATION_SETTINGS",
        organizationID,
        settingType,
        attributeName: deleteItem?.data?.name,
        index: deleteItem?.index,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => {
      // console.log(res);
    })
    .catch(console.error);
};

export const getOrderAttachments = async (orderID) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ORDER_ATTACHMENTS",
        orderID: orderID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getOrderAttachment = async (orderID, fileName) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ORDER_ATTACHMENT",
        orderID: orderID,
        fileName: fileName,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const deleteOrderAttachment = async (orderID, fileName) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "DELETE_ORDER_ATTACHMENT",
        orderID: orderID,
        fileName: fileName,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getOrderDeliveryAttachments = async (orderID) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ORDER_DELIVERY_ATTACHMENTS",
        orderID: orderID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getOrderDeliveryAttachment = async (orderID, fileName) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ORDER_DELIVERY_ATTACHMENT",
        orderID: orderID,
        fileName: fileName,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const deleteOrderDeliveryAttachment = async (orderID, fileName) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "DELETE_ORDER_DELIVERY_ATTACHMENT",
        orderID: orderID,
        fileName: fileName,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getCustomer = async (organizationTablePrefix) => {
  const userInfo = await Auth.currentUserInfo();
  const customerID = userInfo?.attributes?.["custom:customerID"];
  const organizationID = userInfo?.attributes?.["custom:organizationID"];
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_CUSTOMER",
        customerID,
        organizationTablePrefix,
        organizationID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

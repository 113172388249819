import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import NewOrder from "Components/NewOrder";
import { useState } from "react";
// import ActiveOrders from "Components/Orders/ActiveOrders";
// import CancelledOrders from "Components/Orders/CancelledOrders";
// import DeliveredOrders from "Components/Orders/DeliveredOrders";
import OrdersHome from "Components/Orders/OrdersHome";
import InfoPanel from "Components/InfoPanel";

export default function HomeScreen(props) {
  const [value, setValue] = useState("1");
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <TabList
          value={value}
          onChange={(e, newVal) => {
            setValue(newVal);
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="New Order" value="1" />
          <Tab label="Activity" value="2" />
        </TabList>
        <TabPanel value="1">
          <NewOrder />
        </TabPanel>
        <TabPanel value="2">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <OrdersHome />
              <br />
              <br />
              <InfoPanel />
            </Grid>
            {/* <Grid item xs={6}>
              <ActiveOrders />
            </Grid>
            <Grid item xs={6}>
              <DeliveredOrders />
            </Grid> */}
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

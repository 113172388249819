import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  addGlobalUserToCustomer,
  getGlobalUsersByPricingList,
} from "API/GlobalUsers";
import AlertDialog from "Components/AlertDialog";
import { VishcorpContext } from "Context";
import { useContext, useEffect, useState } from "react";

export default function AddGlobalUser(props) {
  const { handleClose, refreshCustomerDetails } = props;
  const { customerDetails, organizationSettings } = useContext(VishcorpContext);
  const [availableGlobalUsers, setAvailableGlobalUsers] = useState(undefined);
  const [selectedGlobalUser, setSelectedGlobalUser] = useState(undefined);
  const [openAddGlobalUser, setOpenAddGlobalUser] = useState(undefined);

  useEffect(() => {
    if (!customerDetails || !organizationSettings?.organizationID) return;
    getGlobalUsersByPricingList(
      customerDetails?.pricingList,
      customerDetails?.surchargeSet,
      organizationSettings?.organizationID,
      organizationSettings?.organizationTablePrefix
    ).then(setAvailableGlobalUsers);
  }, [customerDetails, organizationSettings]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Global User Name</b>
              </TableCell>
              <TableCell>
                <b>Email</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {availableGlobalUsers?.length > 0 ? (
              availableGlobalUsers?.map((e, i) => (
                <TableRow
                  hover
                  key={i}
                  selected={
                    selectedGlobalUser?.globalUserID === e?.globalUserID
                  }
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedGlobalUser(e)}
                >
                  <TableCell>{e?.globalUserName}</TableCell>
                  <TableCell>{e?.email}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div>
        <Button
          variant="contained"
          onClick={() => {
            setOpenAddGlobalUser(true);
          }}
          disabled={selectedGlobalUser === undefined}
        >
          Add Global User
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
          }}
          style={{ marginLeft: 10 }}
        >
          Close
        </Button>
      </div>
      <AlertDialog
        open={openAddGlobalUser}
        dialogTitle="Add Global User?"
        handleClose={() => {
          setOpenAddGlobalUser(false);
        }}
        handleConfirm={async () => {
          await addGlobalUserToCustomer(
            customerDetails?.customerID,
            selectedGlobalUser,
            organizationSettings?.organizationTablePrefix
          );
          refreshCustomerDetails();
          setOpenAddGlobalUser(false);
          handleClose();
        }}
      />
    </div>
  );
}

import { TextField } from "@mui/material";

export default function NumberInput(props) {
  const { defaultValue, value, label, required, register, errors, setValue } =
    props;
  return (
    <TextField
      label={label}
      fullWidth
      size="small"
      {...register(label, {
        required: required ? "Required*" : false,
      })}
      value={value ?? defaultValue ?? ""}
      variant="outlined"
      error={errors?.[label] && required}
      helperText={required && errors?.[label]?.message}
      onChange={(e) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
          setValue(e?.target?.value);
        }
      }}
      // value={value ?? ""}
    />
  );
}

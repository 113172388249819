import { Grid, Paper } from "@mui/material";
import { VishcorpContext } from "Context";
import { useEffect, useState, useContext } from "react";
import CompanySettings from "./CompanySettings";
import Options from "./Options";
import Users from "./Users";
import CostCenters from "./CostCenters";

export default function Settings(props) {
  const { refreshUserInfo } = useContext(VishcorpContext);
  const [selectedSettings, setSelectedSettings] = useState(undefined);
  const [selectedOption, setSelectedOption] = useState("");

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      refreshUserInfo();
    }
  }, [isMounted, refreshUserInfo]);

  useEffect(() => {
    if (!selectedOption) {
      return;
    }
    switch (selectedOption) {
      case "Users":
        setSelectedSettings(<Users />);
        break;
      case "Company Settings":
        setSelectedSettings(<CompanySettings />);
        break;
      case "Cost Centers":
        setSelectedSettings(<CostCenters />);
        break;
      default:
        break;
    }
  }, [selectedOption]);

  return (
    <Grid container sx={{ height: "100%", flexGrow: 1 }}>
      <Grid item xs={2}>
        <Paper sx={{ height: "100vh" }}>
          <Options
            onOptionChanged={(option) => {
              setSelectedOption(option);
            }}
            selectedOption={selectedOption}
          />
        </Paper>
      </Grid>
      <Grid item xs={10}>
        {selectedSettings && selectedSettings}
      </Grid>
    </Grid>
  );
}

export const addCostCenterUser = async (
  customerID,
  costCenterID,
  costCenterUserDetails,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "ADD_COST_CENTER_USER",
        customerID,
        costCenterID,
        costCenterUserDetails,
        organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const updateCostCenterUsers = async (
  customerID,
  costCenterID,
  costCenterUsers,
  userDetails,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/customer-order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_COST_CENTER_USERS",
        customerID,
        costCenterID,
        costCenterUsers,
        userDetails,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

import {
  FormLabel,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import EditOrder from "./EditOrder";
// import AlertDialog from "Components/AlertDialog/AlertDialog";
import OrderLists from "./OrderLists";
import { VishcorpContext } from "Context";
import AlertDialog from "Components/AlertDialog";
import { updateOrder } from "API/Orders";

export default function OrdersHome(props) {
  const {
    drivers,
    activeOrders,
    cancelledOrders,
    completedOrders,
    confirmedOrders,
    refreshOrder,
    selectedOrder,
    setSelectedOrder,
    organizationSettings,
  } = useContext(VishcorpContext);
  const organizationTablePrefix = organizationSettings?.organizationTablePrefix;
  const [editOrder, setEditOrder] = useState(false);
  // const [selectedOrder, setSelectedOrder] = useState(undefined);
  const [modifiedStatus, setModifiedStatus] = useState(undefined);
  const [openUpdateOrder, setOpenUpdateOrder] = useState(false);
  const [orderType, setOrderType] = useState("active");
  const [orderData, setOrderData] = useState(undefined);
  const [assignedDriver, setAssignedDriver] = useState(undefined);
  const [alertText, setAlertText] = useState(undefined);

  useEffect(() => {
    switch (orderType) {
      case "active":
        setOrderData(activeOrders);
        break;
      case "cancelled":
        setOrderData(cancelledOrders);
        break;
      case "completed":
        setOrderData(completedOrders);
        break;
      case "confirmed":
        setOrderData(confirmedOrders);
        break;
      default:
        break;
    }
  }, [
    orderType,
    activeOrders,
    cancelledOrders,
    completedOrders,
    confirmedOrders,
  ]);

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={6}>
        <FormLabel sx={{ fontSize: "2rem" }}>Orders</FormLabel>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          style={{ width: "100%", marginBottom: 15 }}
          size="small"
        >
          <InputLabel id="order-status-label">Status</InputLabel>
          <Select
            labelId="order-status-label"
            label={"Status"}
            fullWidth
            onChange={(e) => setOrderType(e.target.value)}
            value={orderType}
          >
            <MenuItem value={"active"}>Confirmed</MenuItem>
            <MenuItem value={"confirmed"}>Dispatched</MenuItem>
            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
            <MenuItem value={"completed"}>Delivered</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <div style={{ height: 300, overflow: "scroll" }}>
          <OrderLists
            orders={orderData}
            onEditClick={(e) => {
              // setEditOrder(true);
              setSelectedOrder(e);
              setModifiedStatus(e?.orderStatus);
              setAssignedDriver(e?.assignedDriver);
            }}
            activeOrder
          />
        </div>
      </Grid>
      {editOrder && (
        <AlertDialog
          open={editOrder}
          // open={editOrder}
          handleClose={() => {
            setEditOrder(false);
          }}
          customControls={false}
          dialogContent={
            <Grid sx={{ position: "static" }}>
              <FormLabel sx={{ fontSize: "2rem" }}>Edit Order</FormLabel>
              <br />
              <br />
              <EditOrder
                selectedOrder={selectedOrder}
                onDriverAssigned={(e) => setAssignedDriver(e)}
                onStatusChange={(e) => setModifiedStatus(e)}
                activeOrder
              />
              <br />
              <Grid
                container
                sx={{
                  display: "flex",
                  // justifyContent: "right",
                  // alignContent: "right",
                }}
                spacing={1}
              >
                <Grid item>
                  <Button
                    sx={{ borderRadius: 0 }}
                    variant="contained"
                    onClick={() => {
                      if (
                        modifiedStatus !== selectedOrder?.orderStatus ||
                        assignedDriver !== selectedOrder?.assignedDriver
                      ) {
                        setOpenUpdateOrder(true);
                      }
                      if (modifiedStatus !== selectedOrder?.orderStatus) {
                        setAlertText(
                          `Status Changed: ${selectedOrder?.orderStatus} -> ${modifiedStatus}`
                        );
                      }
                      if (assignedDriver !== selectedOrder?.assignedDriver) {
                        setAlertText(
                          `Status Changed: ${
                            drivers?.find(
                              (e) =>
                                e?.driverNumber ===
                                selectedOrder?.assignedDriver
                            )?.driverName
                          } -> ${
                            drivers?.find(
                              (e) => e?.driverNumber === assignedDriver
                            )?.driverName
                          }`
                        );
                      }
                    }}
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ borderRadius: 0 }}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setEditOrder(false);
                      setSelectedOrder(undefined);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}
      <AlertDialog
        open={openUpdateOrder}
        handleClose={() => {
          setOpenUpdateOrder(false);
        }}
        handleConfirm={() => {
          updateOrder(
            selectedOrder,
            modifiedStatus,
            assignedDriver,
            organizationTablePrefix
          ).then((res) => {
            refreshOrder();
            console.log("Status Updated: ", res);
          });
          setEditOrder(false);
          setOpenUpdateOrder(false);
        }}
        dialogTitle={
          <b>
            Confirm Update.
            <br />
            {`Order: ${selectedOrder?.orderID}.`}
          </b>
        }
        dialogContent={<b>{alertText}</b>}
      />
    </Grid>
  );
}

import AddressInput from "../Input/AddressInput";
import CustomCheckBox from "../Input/CustomCheckBox";
import CustomRadio from "../Input/CustomRadio";
import CustomSelect from "../Input/CustomSelect";
import Email from "../Input/Email";
import NumberInput from "../Input/NumberInput";
import Phone from "../Input/Phone";
import Text from "../Input/Text";

export default function RenderInput(props) {
  const {
    defaultValue,
    type,
    label,
    required,
    register,
    errors,
    value,
    setValue,
    options,
    id,
    control,
  } = props;
  switch (type) {
    case "String":
      return (
        <Text
          id={id}
          defaultValue={defaultValue}
          register={register}
          errors={errors}
          value={value}
          setValue={setValue}
          label={label}
          required={required}
        />
      );
    case "Address":
      return (
        <AddressInput
          register={register}
          defaultValue={defaultValue}
          id={id}
          errors={errors}
          value={value}
          setValue={setValue}
          label={label}
          required={required}
        />
      );
    case "Number":
      return (
        <NumberInput
          defaultValue={defaultValue}
          id={id}
          register={register}
          errors={errors}
          value={value}
          setValue={setValue}
          label={label}
          required={required}
        />
      );
    case "Phone":
      return (
        <Phone
          defaultValue={defaultValue}
          id={id}
          register={register}
          errors={errors}
          value={value}
          setValue={setValue}
          label={label}
          required={required}
        />
      );
    case "Email":
      return (
        <Email
          defaultValue={defaultValue}
          id={id}
          register={register}
          errors={errors}
          value={value}
          setValue={setValue}
          label={label}
          required={required}
        />
      );
    case "Options":
      return (
        <CustomSelect
          defaultValue={defaultValue}
          id={id}
          control={control}
          register={register}
          errors={errors}
          value={value}
          setValue={setValue}
          label={label}
          required={required}
          options={options}
        />
      );
    case "Radio":
      return (
        <CustomRadio
          defaultValue={defaultValue}
          id={id}
          control={control}
          register={register}
          errors={errors}
          value={value}
          setValue={setValue}
          label={label}
          required={required}
          options={options}
        />
      );
    case "Checkbox":
      return (
        <CustomCheckBox
          id={id}
          defaultValue={defaultValue}
          control={control}
          register={register}
          errors={errors}
          value={value}
          setValue={setValue}
          label={label}
          required={required}
          options={options}
        />
      );
    default:
      return <></>;
  }
}
